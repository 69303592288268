import {
    Form,
    FormProps,
    FormValues,
} from "@baloise-cfa/form-renderer-frontend";
import { graphql, navigate } from "gatsby";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";

import {
    policyHolderFormConfig,
    QuoteFormKey,
    QuoteFormState,
    quoteFormState,
    QuoteLayout,
    TabsLayout,
    VehicleHeading,
} from "@modules/quote";
import { CompletedCard, Navigation } from "@modules/shared/components";
import { PageNames } from "@modules/shared/enums";
import {
    filterQuotePages,
    findNextInvalidTab,
    getNavigationPages,
} from "@modules/shared/helpers";
import { appState, AppState } from "@modules/shared/state";
import { PageInterface, PageProps, TabsStatus } from "@modules/shared/types";

const getPolicyHolderName = (drivers: QuoteFormState["drivers"]) => {
    if (drivers?.policyholderInfo?.headDriverIsPolicyHolder) {
        return `${drivers?.usualDriver?.firstName} ${drivers?.usualDriver?.lastName}`;
    }
    return `${drivers?.policyholderInfo?.policyholder?.firstName} ${drivers?.policyholderInfo?.policyholder?.lastName}`;
};

const PolicyHolder: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, language, ownPageObjects: allPages } = pageContext;
    const { t } = useTranslation();
    const [formState, setFormState] = useRecoilState(quoteFormState);
    const appData = useRecoilValue<AppState>(appState);
    const [formProps, setFormProps] = useState<
        Pick<FormProps<FormValues>, "dirty" | "isValid">
    >({
        isValid: false,
        dirty: false,
    });

    const policyHolderName = useMemo(
        () => getPolicyHolderName(formState?.drivers),
        [formState?.drivers],
    );

    useEffect(() => {
        if (!appData?.InsuranceChoice?.insurance?.type) {
            // Navigate to the product page when there is no insurance choice selected
            // A quote flow can only started when there is a simulation available
            navigate("/");
        }
    }, [appData]);

    const initialFormValues: QuoteFormState = useMemo(() => {
        return {
            ...formState,
            policyHolder: {
                ...formState?.policyHolder,
                personalDetails: {
                    ...formState?.policyHolder?.personalDetails,
                    firstName:
                        formState?.drivers?.policyholderInfo?.policyholder
                            ?.firstName,
                    lastName:
                        formState?.drivers?.policyholderInfo?.policyholder
                            ?.lastName,
                },
            },
        };
        // Only run this when initializing the page
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = (
        values: FormValues,
        tabsStatus: TabsStatus,
        nextPage?: PageInterface,
    ): void => {
        updateFormState(values);
        const nextInvalidTab = findNextInvalidTab(
            tabsStatus,
            page,
            allPages,
            language,
        );

        if (nextInvalidTab) {
            navigate(nextInvalidTab.paths[language]);
            return;
        }
        if (nextPage) {
            navigate(nextPage.paths[language]);
        }
    };

    const updateFormState = (formValues: QuoteFormState): void => {
        setFormState({
            ...formValues,
            drivers: {
                ...formValues.drivers,
                policyholderInfo: {
                    ...formValues.drivers?.policyholderInfo,
                    policyholder: {
                        ...formValues.drivers?.policyholderInfo?.policyholder,
                        firstName:
                            formValues.policyHolder?.personalDetails?.firstName,
                        lastName:
                            formValues.policyHolder?.personalDetails?.lastName,
                    },
                },
            },
        });
    };

    const handleOnChange = (
        values: FormValues,
        props: Pick<FormProps<FormValues>, "dirty" | "isValid">,
    ): void => {
        setFormProps(props);
        updateFormState(values);
    };

    const renderHeaderComponent = useCallback(
        (tabsStatus: TabsStatus, nextPage?: PageInterface) => {
            const showCompleteCard = formProps.dirty
                ? formProps.isValid
                : tabsStatus && tabsStatus[page.name]?.isValid;
            const nextInvalidTab = findNextInvalidTab(
                tabsStatus,
                page,
                allPages,
                language,
            );

            if (showCompleteCard) {
                return (
                    <CompletedCard
                        nextPage={nextInvalidTab ?? nextPage}
                        currentPageText={policyHolderName}
                        language={language}
                    />
                );
            }
        },
        [language, page, policyHolderName, formProps, allPages],
    );

    return (
        <QuoteLayout
            title={t("quote.vehicle.title")}
            page={page}
            allPages={allPages}
            language={language}
        >
            <TabsLayout
                title={t("quote.vehicle.title")}
                description={t("quote.vehicle.description")}
                language={language}
                allPages={allPages}
                activeTabName={page.name}
            >
                {({ tabs, tabsStatus }) => {
                    const filteredTabs = filterQuotePages(allPages, tabs);
                    const { prevPage, nextPage } = getNavigationPages(
                        filteredTabs,
                        page,
                    );
                    const isLastTab =
                        nextPage?.name === PageNames.QuoteInsurances;

                    return (
                        <>
                            <VehicleHeading
                                subTitle={t(
                                    "quote.drivers.form.policyholderInfo",
                                )}
                                title={policyHolderName}
                            />
                            <Form
                                scrollToFieldError
                                errorMessageCardTitle={
                                    t("all.errormessage.title") as string
                                }
                                errorMessageCardSubTitle={
                                    t("all.errormessage.text") as string
                                }
                                formContext={{
                                    t,
                                    nameSpace: QuoteFormKey.PolicyHolder,
                                    fieldWrapper: {
                                        optionalLabel: "all.optional",
                                    },
                                }}
                                initialValues={initialFormValues}
                                onSubmit={(values) =>
                                    handleSubmit(values, tabsStatus, nextPage)
                                }
                                onChange={handleOnChange}
                                fields={policyHolderFormConfig.fields}
                                headerComponent={() =>
                                    renderHeaderComponent(tabsStatus, nextPage)
                                }
                            >
                                <Navigation
                                    t={t}
                                    language={language}
                                    prevPage={prevPage}
                                    nextPage={nextPage}
                                    nextPageBtnText="all.next"
                                    prevPageBtnText="all.previous"
                                    nextColor={isLastTab ? "primary" : "info"}
                                />
                            </Form>
                        </>
                    );
                }}
            </TabsLayout>
        </QuoteLayout>
    );
};

export default PolicyHolder;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
